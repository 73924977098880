<template>
  <div style="height: 100vh" class="m-5">
    <div class="columns is-centered is-vcentered" :class="{ 'mt-4': $route.query.isForex }">
      <div class="column is-4-desktop is-6-tablet is-full-mobile">
        <div class="card">
          <div class="card-header" style="background: linear-gradient(89.31deg, #211895 1.38%, #6EC943 97.6%);">
            <div class="card-header-title is-flex is-justify-content-space-between is-align-items-center">
              <img width="150" :src="`/logos/${site}/logo.svg`" />
              <img width="150" :src="`/logos/${site}/bank-transfer.svg`" />
            </div>
          </div>
          <div class="card-content is-centered">
            <b-field label="Banka" v-if="transaction.bankAccount">
              <b-input v-model="transaction.bankAccount.bankName" disabled expanded id="bank" />
              <p class="control">
                <b-button type="is-success" icon-left="clipboard-check-multiple" @click="copy('bank')"></b-button>
              </p>
            </b-field>
            <b-field label="Alıcı Ad & Soyad" v-if="transaction.bankAccount">
              <b-input v-model="transaction.bankAccount.name" disabled expanded id="bankAccountName" />
              <p class="control">
                <b-button type="is-success" icon-left="clipboard-check-multiple"
                  @click="copy('bankAccountName')"></b-button>
              </p>
            </b-field>
            <b-field label="Alıcı Adresi" v-if="transaction.bankAccount">
              <b-input v-model="transaction.bankAccount.iban" disabled expanded id="bankAccount" />
              <p class="control">
                <b-button type="is-success" icon-left="clipboard-check-multiple" @click="copy('bankAccount')"></b-button>
              </p>
            </b-field>
            <b-field label="Tutar(₺)" v-if="transaction.total">
              <b-input v-model="transaction.total" disabled expanded />
            </b-field>
            <hr />
            <p class="mb-3 notification has-background-success-light p-3">
              Ödemenizi yaptıktan sonra "Ödemeyi Gerçekleştirdim" butonuna basınız, <br /> İşlem kontrol edildikten sonra bakiyenize yansıyacaktır. Lütfen sayfadan ayrılmayınız
            </p>
            <hr />
            <div class="is-flex is-full is-justify-content-end">
              <b-button type="is-danger" @click="cancel">İptal Et</b-button>
              <b-button type="is-primary" class="ml-3" expanded @click="pay">Ödemeyi Gerçekleştirdim</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TransactionService } from '@/services'
export default {
  name: 'BankTransferPay',
  data() {
    return {
      transaction: {},
      loading: false,
      interval: null
    }
  },
  mounted() {
    const html = document.getElementsByTagName('html')[0]
    html.classList = []
    this.init()
    /*this.interval = setInterval(() => {
      this.init()
    },10000)*/
  },
  methods: {
    pay() {
      this.loading = true
      TransactionService.bankTransferPay({ transactionId: this.transaction.uuid })
        .then(res => {
          this.$buefy.toast.open({ message: res.data.message, position: 'is-top', type: 'is-warning' })
          setTimeout(() => {
            if(res.data.redirectUrl) {
              window.location.href = res.data.redirectUrl
            } else {
              this.$router.push({ name: 'paymentError' })
            }
          }, 3000)
        })
        .finally(() => this.loading = false)
    },
    init() {
      TransactionService.check(this.$route.params.id)
        .then(({ data }) => {
          this.transaction = data
          if (data.status === 'SUCCESSFUL') {
            // clearInterval(this.interval)
            if (data.requestParams.successRedirectUrl)
              window.location.href = data.requestParams.successRedirectUrl
            else
              this.$router.push({ name: 'paymentSuccess' })
          }
          if (data.status === 'UNSUCCESSFUL' || data.status === 'WAITING') {
            // clearInterval(this.interval)
            if (data.requestParams.failRedirectUrl)
              window.location.href = data.requestParams.failRedirectUrl
            else
              this.$router.push({ name: 'paymentError' })
          }
        })
        .catch(err => {
          this.$buefy.toast.open({ message: err.message, position: 'is-top-right', type: 'is-danger' })
        })
    },
    copy(val) {
      const copyText = document.getElementById(val)
      copyText.select()
      copyText.setSelectionRange(0, 99999) /* For mobile devices */
      navigator.clipboard.writeText(copyText.value)
      this.$buefy.toast.open({
        duration: 3000,
        message: 'Kopyalandı!',
        position: 'is-bottom',
        type: 'is-success'
      })
    },
    cancel() {
      this.loading = true
      TransactionService.cancel({ transactionId: this.$route.params.id })
        .then(res => {
          this.$buefy.toast.open({
            message: res.data.message,
            position: 'is-top-right',
            type: 'is-success',
            duration: 2000
          })
          setTimeout(() => {
            window.location.href = res.data.redirectUrl
          }, 2000)
        })
        .catch(err => this.$buefy.toast.open({ message: err.message, position: 'is-bottom', type: 'is-info' }))
        .finally(() => this.loading = false)
    }
  },
  beforeDestroy() {
    // clearInterval(this.interval)
  }
}
</script>

<style>
.qrcode>canvas {
  border: 6px solid #ddd;
  padding: .5rem;
  border-radius: 12px;
}

.input[type="text"]::placeholder {
  color: #000000 !important
}

.input[type="text"] {
  color: #000000 !important
}</style>
